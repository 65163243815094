import { useMemo } from "react"

import { Stack, Card, TableHead, TableRow } from "@mui/material"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { calcPrizeRankSales } from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { getDaysAgo, getRatioLabel, getToday } from "src/utils"

type PrizeRankSalesDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(7),
  end: getToday(),
}

const prizeRankSalesDateRangeLabelState =
  atom<PrizeRankSalesDateRangeLabelState>({
    key: "prizeRankSalesDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizeRankSales: React.FC = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizeRankSalesDateRangeLabelState,
  )

  return (
    <MainContentLayout
      title="ランク別の実績一覧"
      renderFilter={() => (
        <Card sx={{ p: 3 }}>
          <DateRangePicker
            dateRangeLabel={dateRangeLabel}
            setDateRangeLabel={setDateRangeLabel}
            startInputLabel="開始日"
            endInputLabel="終了日"
          />
        </Card>
      )}
      renderContent={() => <PrizeRankSalesTable />}
    />
  )
}

const PrizeRankSalesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const { isAdmin } = useUserRole()

  const dateRangeLabel = useRecoilValue(prizeRankSalesDateRangeLabelState)
  const { start, end } = dateRangeLabel

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const sales = useMemo(
    () => calcPrizeRankSales(prizeBoothSales, start, end),
    [prizeBoothSales, start, end],
  )

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 360px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        noMargin
        items={sales.items}
        stateKey="PrizeRankSalesTable"
        header={
          <TableHead
            sx={{
              th: {
                textAlign: "center",
                whiteSpace: "nowrap",
                py: 1,
              },
            }}
          >
            <TableRow>
              <ExtTableCell sticky fixedWidth={100} border>
                ランク
              </ExtTableCell>
              <ExtTableCell>平均</ExtTableCell>
              <ExtTableCell>売上</ExtTableCell>
              <ExtTableCell>ブース数</ExtTableCell>
              <ExtTableCell>払出個数</ExtTableCell>
              <ExtTableCell>売上構成比</ExtTableCell>
              <ExtTableCell>ブース数構成比</ExtTableCell>
              <ExtTableCell>平均/日</ExtTableCell>
              <ExtTableCell>平均単価</ExtTableCell>
              <ExtTableCell>景品代</ExtTableCell>
              <ExtTableCell>P/O</ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell header word="ランクPDPB" rowSpan={2} />
                  <AdminExtTableCell header word="店舗全体PDPB" rowSpan={2} />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(item) => {
          return (
            <TableRow
              key={"PrizeRankIpSalesTable" + item.prizeRank}
              sx={{ td: { py: 1 } }}
            >
              <ExtTableCell sticky sx={{ textAlign: "center" }}>
                {item.prizeRank === "all"
                  ? "全体"
                  : item.prizeRank === "unknown"
                    ? "(景品不明)"
                    : item.prizeRank}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.averageSales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.count.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.payout?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.salesRatio)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.countRatio)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.averageDailySales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.averageUnitPrice?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.payoutPrice?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.payoutRate)}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.pdpb?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={sales.total.pdpb?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
