import React, { SetStateAction } from "react"

import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  TextField,
  Button,
  Dialog,
} from "@mui/material"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { Prize } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { filterAccordionSearchState } from "src/recoil"

export const InventoryPrizeEstimationsVerify: React.FC = () => {
  return (
    <MainContentLayout
      title="カウントなし異常データ確認"
      renderFilter={() => <InventoryPrizeEstimationsVerifyFilter />}
      renderContent={() => <InventoryPrizeEstimationsVerifyInner />}
    />
  )
}

type InventoryPrizeEstimationsVerifySearchParams = {
  prizeName?: string
  prizeCdGigoNavi?: string
  prizeCdSeams?: string
  prizeNameKana?: string // Prize["prizeNameKana"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

const defaultSearchParams: InventoryPrizeEstimationsVerifySearchParams = {}

const InventoryPrizeEstimationsVerifyFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams: InventoryPrizeEstimationsVerifySearchParams =
    recoilSearchParams["inventoryPrizeNoCountVerifySearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<InventoryPrizeEstimationsVerifySearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      inventoryPrizeNoCountVerifySearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCdGigoNavi",
          label: "景品CD（GiGO NAVI）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCdSeams",
          label: "景品CD（SEAMS）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
      ]}
    />
  )
}

type TableData = {
  prizeGigoNavi: {
    prizeName: string
    prizeCd: string
    price: number
  }
  payoutCount: number
  prizeSeams: {
    prizeName: string
    prizeCd: string
    price: number
  }
  seamsDataCount: number
}

const InventoryPrizeEstimationsVerifyInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: InventoryPrizeEstimationsVerifySearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "inventoryPrizeNoCountVerifySearchParams"
    ] ?? defaultSearchParams

  // TODO API呼ぶ時に渡す
  const { prizeCdGigoNavi, prizeCdSeams, prizeName } = searchParams

  const navigate = useNavigate()

  const tableData: TableData[] = [
    {
      prizeGigoNavi: {
        prizeName: "Prize A",
        prizeCd: "A001",
        price: 1000,
      },
      payoutCount: 10,
      prizeSeams: {
        prizeName: "Prize A",
        prizeCd: "S001",
        price: 1000,
      },
      seamsDataCount: 5,
    },
    {
      prizeGigoNavi: {
        prizeName: "Prize B",
        prizeCd: "B002",
        price: 2000,
      },
      payoutCount: 20,
      prizeSeams: {
        prizeName: "Prize B",
        prizeCd: "S002",
        price: 2000,
      },
      seamsDataCount: 10,
    },
    {
      prizeGigoNavi: {
        prizeName: "Prize C",
        prizeCd: "C003",
        price: 3000,
      },
      payoutCount: 30,
      prizeSeams: {
        prizeName: "Prize C",
        prizeCd: "S003",
        price: 3000,
      },
      seamsDataCount: 15,
    },
  ]

  return (
    <Stack sx={{ gap: 2 }}>
      <InventoryPrizeEstimationsVerifyTable tableData={tableData} />
      <Button
        variant="outlined"
        onClick={() => {
          navigate(`/arcades/${arcadeCd}/inventory/prizes/no-count/adjust`)
        }}
        fullWidth
        disabled={tableData.length > 0}
      >
        棚卸カウント無し最終調整へ
      </Button>
    </Stack>
  )
}

interface InventoryPrizeEstimationsVerifyTableProps {
  tableData: TableData[]
}

const InventoryPrizeEstimationsVerifyTable: React.FC<
  InventoryPrizeEstimationsVerifyTableProps
> = ({ tableData }) => {
  const { arcadeCd } = useParams()

  const [correctAlertPrizeCdSeams, setCorrectAlertPrizeCdSeams] =
    React.useState<string | null>(null)

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={tableData}
        stateKey="inventoryPrizeNoCountVerifyTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={240}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                景品CD
                <br />
                （GiGO NAVI）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>払い出し個数</ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                景品CD
                <br />
                （SEAMS）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>SEAMSデータ個数</ExtTableCell>
              <ExtTableCell fixedWidth={160}>修正ボタン</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, i) => {
          return (
            <TableRow sx={{ td: { p: 2 } }} key={i}>
              <ExtTableCell border sticky zIndex={99}>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/${item.prizeGigoNavi.prizeCd}`}
                  underline="none"
                >
                  <Typography variant="subtitle1">
                    {item.prizeGigoNavi.prizeName}
                  </Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.prizeGigoNavi.prizeCd}</ExtTableCell>
              <ExtTableCell>{item.payoutCount}</ExtTableCell>
              <ExtTableCell>{item.prizeSeams.prizeCd}</ExtTableCell>
              <ExtTableCell>{item.seamsDataCount}</ExtTableCell>
              <ExtTableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCorrectAlertPrizeCdSeams(item.prizeSeams.prizeCd)
                    }}
                    color="error"
                    size="small"
                  >
                    景品CDの修正
                  </Button>
                </Stack>
              </ExtTableCell>
            </TableRow>
          )
        }}
      />
      <Dialog
        open={!!correctAlertPrizeCdSeams}
        onClose={() => setCorrectAlertPrizeCdSeams(null)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">本当に景品を修正しますか？</Typography>
          {(() => {
            const targetData = tableData.find(
              (item) => item.prizeSeams.prizeCd === correctAlertPrizeCdSeams,
            )
            if (!targetData) return

            return (
              <>
                <Typography variant="h3">修正前 {"->"} 修正後</Typography>
                <Typography variant="body1">
                  プライズ名: {targetData.prizeSeams.prizeName} {"->"}{" "}
                  {targetData.prizeGigoNavi.prizeName}
                </Typography>
                <Typography variant="body1">
                  プライズCD: {targetData.prizeSeams.prizeCd} {"->"}{" "}
                  {targetData.prizeGigoNavi.prizeCd}
                </Typography>
              </>
            )
          })()}
          <Stack sx={{ gap: 1 }}>
            <Button
              // TODO
              onClick={() => setCorrectAlertPrizeCdSeams(null)}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              color="error"
            >
              実行する
            </Button>
            <Button
              onClick={() => setCorrectAlertPrizeCdSeams(null)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}
