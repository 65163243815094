import React, { SetStateAction, useState } from "react"

import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  Card,
  TextField,
  Button,
  Dialog,
} from "@mui/material"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { Prize } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { filterAccordionSearchState } from "src/recoil"

export const InventoryPrizeEstimationsAdjust: React.FC = () => {
  return (
    <MainContentLayout
      title="棚卸カウント無し最終調整"
      renderFilter={() => <InventoryPrizeEstimationsAdjustFilter />}
      renderContent={() => <InventoryPrizeEstimationsAdjustInner />}
    />
  )
}

type InventoryPrizeEstimationsAdjustSearchParams = {
  prizeName?: string
  prizeCd?: string
  prizeNameKana?: string // Prize["prizeNameKana"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

const defaultSearchParams: InventoryPrizeEstimationsAdjustSearchParams = {}

const InventoryPrizeEstimationsAdjustFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams: InventoryPrizeEstimationsAdjustSearchParams =
    recoilSearchParams["inventoryPrizeNoCountAdjustSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<InventoryPrizeEstimationsAdjustSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      inventoryPrizeNoCountAdjustSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
      ]}
    />
  )
}

type TableData = {
  prize: {
    prizeName: string
    prizeCd: string
    price: number
  }
  seamsDataCount: number
  gigoNaviDifference: number
  finalDifference: number
  finalRemaining: number
}

const InventoryPrizeEstimationsAdjustInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: InventoryPrizeEstimationsAdjustSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "inventoryPrizeNoCountAdjustSearchParams"
    ] ?? defaultSearchParams

  // TODO API呼ぶ時に渡す
  const { prizeCd, prizeName, prizeNameKana, ipName, makerName } = searchParams

  const [isExecuteDialogOpen, setIsExecuteDialogOpen] = useState(false)

  const tableData: TableData[] = [
    {
      prize: {
        prizeName: "Teddy Bear",
        prizeCd: "TB123",
        price: 100,
      },
      seamsDataCount: 50,
      gigoNaviDifference: 5,
      finalDifference: 3,
      finalRemaining: 42,
    },
    {
      prize: {
        prizeName: "Toy Car",
        prizeCd: "TC456",
        price: 200,
      },
      seamsDataCount: 30,
      gigoNaviDifference: 2,
      finalDifference: 1,
      finalRemaining: 27,
    },
    {
      prize: {
        prizeName: "Doll",
        prizeCd: "D789",
        price: 300,
      },
      seamsDataCount: 20,
      gigoNaviDifference: 1,
      finalDifference: 0,
      finalRemaining: 19,
    },
  ]

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack>
        <InventoryPrizeEstimationsAdjustTable tableData={tableData} />
      </Stack>

      <Stack sx={{ gap: 1 }}>
        <Card sx={{ p: 2 }}>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">最終景品代</Typography>
            <Typography variant="subtitle1">
              {/* 多分違うので確認 */}
              {tableData.reduce(
                (acc, item) =>
                  acc +
                  item.prize.price *
                    (item.seamsDataCount - item.finalDifference),
                0,
              )}
            </Typography>
          </Stack>
          <Stack
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography variant="subtitle1">GiGO NAVI景品代</Typography>
            <Typography variant="subtitle1">
              {/* 多分違うので確認 */}
              {tableData.reduce(
                (acc, item) =>
                  acc +
                  item.prize.price *
                    (item.seamsDataCount - item.gigoNaviDifference),
                0,
              )}
            </Typography>
          </Stack>
        </Card>
      </Stack>

      <Button
        variant="contained"
        onClick={() => {
          setIsExecuteDialogOpen(true)
        }}
        fullWidth
      >
        棚卸カウント無し処理を確定する
      </Button>

      <Dialog
        open={isExecuteDialogOpen}
        onClose={() => setIsExecuteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">棚卸カウント無し処理の確認</Typography>

          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              景品確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO NAVI上の景品棚卸データに変更が加えられなくなります
              </li>
              <li>棚／ブース／景品についた「済」マークが全て消えます</li>
            </ul>
          </Typography>

          <Stack sx={{ gap: 1 }}>
            <Button
              // TODO
              onClick={() => setIsExecuteDialogOpen(false)}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              color="error"
            >
              棚卸カウント無し処理をする
            </Button>
            <Button
              onClick={() => setIsExecuteDialogOpen(false)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}

interface InventoryPrizeEstimationsAdjustTableProps {
  tableData: TableData[]
}

const InventoryPrizeEstimationsAdjustTable: React.FC<
  InventoryPrizeEstimationsAdjustTableProps
> = ({ tableData }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [deleteCountAlertPrizeCd, setDeleteCountAlertPrizeCd] = React.useState<
    string | null
  >(null)

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={tableData}
        stateKey="inventoryPrizeNoCountAdjustTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  whiteSpace: "nowrap",
                  textAlign: "center",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={240}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品CD</ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                SEAMSデータ個数
                <br />
                （初期数）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                GiGO NAVI
                <br />
                理論差異
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                最終差異
                <br />
                （最終処理数）
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>
                最終残数
                <br />
                （最終残数）
              </ExtTableCell>
              <ExtTableCell fixedWidth={160}>消し込みボタン</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, i) => {
          return (
            <TableRow sx={{ td: { p: 2 } }} key={i}>
              <ExtTableCell border sticky zIndex={99}>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/${item.prize.prizeCd}`}
                  underline="none"
                >
                  <Typography variant="subtitle1">
                    {item.prize.prizeName}
                  </Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.prize.prizeCd}</ExtTableCell>
              <ExtTableCell>{item.seamsDataCount}</ExtTableCell>
              <ExtTableCell>{item.gigoNaviDifference}</ExtTableCell>
              <ExtTableCell>{item.finalDifference}</ExtTableCell>
              <ExtTableCell>{item.finalRemaining}</ExtTableCell>
              <ExtTableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setDeleteCountAlertPrizeCd(item.prize.prizeCd)
                    }}
                    color="error"
                    size="small"
                  >
                    消し込み
                  </Button>
                </Stack>
              </ExtTableCell>
            </TableRow>
          )
        }}
      />
      <Dialog
        open={!!deleteCountAlertPrizeCd}
        onClose={() => setDeleteCountAlertPrizeCd(null)}
        maxWidth="sm"
        fullWidth
      >
        <Stack sx={{ p: 3, gap: 2 }}>
          <Typography variant="h2">本当に消し込みを実行しますか？</Typography>
          {(() => {
            const targetData = tableData.find(
              (item) => item.prize.prizeCd === deleteCountAlertPrizeCd,
            )
            if (!targetData) return

            return (
              <>
                <Typography variant="h3">
                  プライズ名: {targetData.prize.prizeName}
                </Typography>
                <Stack sx={{ pb: 1 }}>
                  <Typography>
                    SEAMSデータ個数（初期数）: {targetData.seamsDataCount}
                  </Typography>
                  <Typography>
                    GiGO NAVI理論差異: {targetData.gigoNaviDifference}
                  </Typography>
                  <Typography>
                    最終差異（最終処理数）: {targetData.finalDifference}
                  </Typography>
                  <Typography>
                    最終残数（最終残数）: {targetData.finalRemaining}
                  </Typography>
                </Stack>
              </>
            )
          })()}
          <Stack sx={{ gap: 1 }}>
            <Button
              // TODO
              onClick={() => setDeleteCountAlertPrizeCd(null)}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              color="error"
            >
              実行する
            </Button>
            <Button
              onClick={() => setDeleteCountAlertPrizeCd(null)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              キャンセル
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  )
}
