import { useMemo } from "react"

import { Box, Link, Typography } from "@mui/material"
import { useParams, Link as RouterLink } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizes } from "src/api/prizes"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  PrizeFilter,
  prizeSearchParamsState,
} from "src/components/organisms/prizes/PrizeFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortPrizes } from "src/domains/prizes/prizeRepository"
import { useResource } from "src/hooks/useResource"

export const PrizesSearch = () => {
  return (
    <MainContentLayout
      title="過去取扱景品一覧"
      renderFilter={() => <PrizeFilter />}
      renderContent={() => <PrizesSearchInner />}
    />
  )
}

const PrizesSearchInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams = useRecoilValue(prizeSearchParamsState)

  const prizesReturn = useResource({
    subject: "景品一覧の取得",
    fetch: arcadeCd
      ? () => getPrizes(arcadeCd, { ...searchParams })
      : undefined,
    recoilKey: `getPrizes:${JSON.stringify(searchParams)}`,
  })

  const prizes = useMemo(() => {
    const prizes = prizesReturn.resource?.data.prizes || []
    if (searchParams.sortBy !== "") {
      return sortPrizes(prizes, searchParams.sortBy)
    }
    return prizes
  }, [prizesReturn, searchParams])

  return (
    <PaginatedTable
      noMargin
      items={prizes}
      stateKey="prizesSearchTable"
      renderRow={(prize) => (
        <TableBorderedRow
          key={prize.prizeCd}
          sx={(theme) => ({
            "&:hover": {
              cursor: "pointer",
              td: { background: theme.palette.neutral[200] },
            },
          })}
        >
          <ExtTableCell sx={{ p: 0 }}>
            <Link
              to={`/arcades/${arcadeCd}/prizes/${prize.prizeCd}`}
              component={RouterLink}
              underline="none"
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" color="primary" pb={1}>
                  {prize.prizeName}
                </Typography>
                <Typography variant="caption" color="gray.90">
                  {prize.prizeCd}
                </Typography>
              </Box>
            </Link>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
